@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.report_wrapper {
    font-family: "Poppins", sans-serif !important;
}

.header_text {
    color: white;
    font-size: medium;
}

.dataTable {
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;
}

.dataTable th {
    text-align: left;
    border: 1px solid black;
    padding: 5px;
    font-size: 14px;

}


.dataTable tr:nth-child(even) {
    background-color: lightgray;
}

.dataTable td {
    border: 1px solid black;
    padding: 2px;
    font-size: 14px;
    padding-left: 8px;
    text-align: start;

}

#seuils {
    color: gray;
}

.wrapper_padding {
    padding: 3px 16px;
}

.arrow_list {
    list-style-type: "→ " !important;
    padding-left: 100px;
    color: indigo;
    font-style: italic;
}

.number_list {
    list-style-type: var(--num) !important;

}

.dataTable td:first-child {
    font-weight: 600;
}
.multiple_data_row th{
    padding: 0px;
    font-size: 12px;
    padding-left: 15px;
}

.multiple_data_row td{
    padding: 0px;
    font-size: 12px;
    padding-left: 15px;
}