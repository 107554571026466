.MuiDataGrid-row {
    cursor: pointer;
}

.eqs_window {
    display: 'flex';
    justify-content: center;
    align-items: center;
}

.highcharts-container {
    width: 100% !important;
    height: 100% !important;
}

.gradient_card {
    background: #136a8a;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #267871, #136a8a);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #267871, #136a8a);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


}

.admin_banner {
    background: #FDC830;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #F37335, #FDC830);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #F37335, #FDC830);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.MuiDataGrid-cell:focus {
    outline: none !important;
}