:root {
    --home-color: #0281AF;
}

.home_header {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%);

}

#wrapper {
    width: 100%;
    height: 100vh;
    position: absolute;
    inset: 0;
    background-color: whitesmoke;
    transform-origin: bottom bottom;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    z-index: 999;
}

#init_screen {
    top: 50%;
    /* position the top  edge of the element at the middle of the parent */
    left: 50%;
    /* position the left edge of the element at the middle of the parent */
    position: absolute;
    transform: translate(-50%, -50%);
    /* This is a shorthand of
                                         translateX(-50%) and translateY(-50%) */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.arioneo {
    font-size: 20px;
    letter-spacing: 3px;
    text-transform: uppercase;
    display: block;
    text-align: center;
}

.arioneo_sub {
    font-size: x-small;
    letter-spacing: 3px;
    color: gray;
    display: block;
    text-align: center;

}

.styled_button {
    text-decoration: none;
    border-radius: 25px;
    background-color: var(--home-color);
    color: white;
    padding: 5px 15px;
    font-size: 14px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 5px;
}

.inverted {
    -webkit-filter: invert(100%);
    /* Safari/Chrome */
    filter: invert(100%);

}

.logo_home {
    bottom: 3%;
    /* position the top  edge of the element at the middle of the parent */
    left: 50%;
    /* position the left edge of the element at the middle of the parent */
    position: absolute;
    transform: translate(-50%, 3%);
    /* This is a shorthand of
                                         translateX(-50%) and translateY(-50%) */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#platforme_img {
    width: 484px;
    position: absolute;
    left: -75px;
    left: 60px;
    bottom: 79px;
    height: 292px;
}

#line {
    width: 47%;
    height: 70px;
    position: relative;
    overflow: hidden;
}

#line span {
    position: absolute;
    font-size: 3rem;
    line-height: 4.2rem;
}

@media only screen and (max-width: 600px) {
    #line span {
        position: absolute;
        font-size: 2rem;
        line-height: 4.2rem;
    }
    .styled_button {
        text-decoration: none;
        border-radius: 25px;
        background-color: var(--home-color);
        color: white;
        padding: 5px 15px;
        font-size: 12px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        gap: 5px;
    }
}